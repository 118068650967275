<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- logo -->
          <vuexy-logo style="width: 100px;height: 40px;"/>
        </b-link>

        <b-card-title class="mb-1"> ورود دو مرحله ای </b-card-title>
        <b-card-text class="mb-2">
          پیامکی از طریق سیستم که حاوی کد ورود است برای شماره زیر ارسال گردید.
          هنگامی که کد را دریافت کردید آن را در باکس زیر وارد کنید تا عملیات
          ورود به سیستم تکمیل گردد.
          <br />شماره شما :
          <strong dir="ltr">{{ phone_number ? phone_number : "..." }}</strong>
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules" #default="{ invalid }">
          <b-form class="auth-forgot-password-form mt-2" @submit.prevent>
            <!-- integer -->
            <b-form-group label="کد" label-for="forgot-password-integer">
              <validation-provider
                #default="{ errors }"
                name="number"
                rules="required|integer"
              >
                <b-form-input
                  id="forgot-password-integer"
                  v-model="otpCode"
                  :state="errors.length > 0 ? false : null"
                  name="forgot-password-integer"
                  placeholder="کد ارسال شده به شماره تلفن همراه خود را وارد کنید"
                />
                <small class="text-danger">{{
                  errors[0] ? "پر کردن فیلد الزامی است" : ""
                }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              class="d-flex justify-content-center align-items-center"
              type="submit"
              :disabled="invalid || isLoading"
              @click="verifyOtp()"
            >
              ثبت کد و ورود
              <b-spinner
                v-show="isLoading"
                class="position-absolute"
                small
                type="grow"
              />
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BCard,
  BLink,
  BSpinner,
  BCardText,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, integer } from "@validations";
import axios from "axios";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    VuexyLogo,
    BCard,
    BLink,
    BSpinner,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      isLoading: false,
      otpCode: "",
      required,
      integer,
      required,
      phone_number: "",
    };
  },
  methods: {
    verifyOtp() {
      this.isLoading = true;
      window.localStorage.setItem("access_token", "");
      window.localStorage.setItem("user_data", "");
      axios
        .post("/api/v1/admin/auth/verify", { pin: this.otpCode })
        .then((response) => {
          if (response.status == 200) {
            this.isLoading = false;
              window.localStorage.setItem(
                "access_token",
                response.data.meta.access_token
              );
            window.localStorage.setItem(
              "user_data",
              response.data.data.username
            );
            window.localStorage.setItem("phone_number", "");
            axios.defaults.headers.common["Authorization"] = `Bearer ${response.data.meta.access_token}`;
            router.push("/");
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "خطا",
              icon: "XOctagonIcon",
              text: "مشکلی پیش آمده.",
            },
          });
        });
    },
  },
  mounted() {
    this.phone_number = window.localStorage.getItem("phone_number");
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>